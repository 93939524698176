<mat-sidenav-container class="sidenav-container">
  <!--mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item href="#">Link 1</a>
      <a mat-list-item href="#">Link 2</a>
      <a mat-list-item href="#">Link 3</a>
    </mat-nav-list>
  </mat-sidenav-->
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <span class="heading">Warbox</span>
    </mat-toolbar>
    <app-warbox></app-warbox>
  </mat-sidenav-content>
  hello
</mat-sidenav-container>


