import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  live = 0;

  constructor(private http: HttpClient) { }

  public upload(data) {
    if (this.live == 1) {
      return this.http.post<any>('/upload/', data);
    }
    else {
      return this.http.post<any>('http://127.0.0.1:8000/api/ror/warboxupload/', data);
    }
    
  }


}
