import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fightFilter',
  pure: false
})
export class FightFilterPipe implements PipeTransform {

  transform(fights:any, filter: string): any {
    if (!fights || !filter) {
        return fights;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    console.log(fights['careerData'])
    fights.forEach(element => {
      console.log(element)
    });
}

}
