import { Component, OnInit, ViewChild } from '@angular/core';
import { UploadService } from '../upload.service';
import { ParseService } from '../parse.service';

import * as abilityList from './ability_dict.json';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

export interface UserData {
  id: string;
  name: string;
  progress: string;
  color: string;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
  'maroon',
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'purple',
  'fuchsia',
  'lime',
  'teal',
  'aqua',
  'blue',
  'navy',
  'black',
  'gray',
];
const NAMES: string[] = [
  'Maia',
  'Asher',
  'Olivia',
  'Atticus',
  'Amelia',
  'Jack',
  'Charlotte',
  'Theodore',
  'Isla',
  'Oliver',
  'Isabella',
  'Jasper',
  'Cora',
  'Levi',
  'Violet',
  'Arthur',
  'Mia',
  'Thomas',
  'Elizabeth',
];

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  file: any;
  totalDmg: number = 0;
  totalHits: number = 0;
  totalBlock: number = 0;
  totalParry: number = 0;

  totals: any = {};

  data: any = [];
  timestamps: any[];
  startTime: any;
  lastTime: any;
  ready: boolean = false;
  loading: boolean = false;

  fights: any[] = [[]];
  fightData: any[] = [];
  allHealedData: any;
  careerData: any = {};
  careerDataOut: any = {};
  abilityData: any = {};
  abilityUsed: any = [];
  fightInfo: any = {};
  timeStampData: any = {};
  abilities: any = {};
  charName: string;
  charRealm: string;

  fightSearch: string[] = [];
  fightSearchOut: string[] = [];
  fightSearchHeal: string[] = [];
  selectedClass: string[] = [];
  avgSingle: boolean[] = [];

  allAttackers: any;
  deathblows: any;
  dbList: any[] = [{ type: 0, value: 0 }];
  deathblowAbility: any;
  dbAbilityList: any[];
  sortDbList: any[];
  sortDBability: any[];

  displayedColumns: string[] = ['name', 'dbs', 'died', 'kdiff'];

  displayedColumnsX: string[] = ['id', 'name', 'progress', 'color'];
  dataSource: MatTableDataSource<any>;
  dataSourceX: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  /*
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  */
  destGroupHeals = [
    //DOK
    "Khaine's Vigour",
    "Khaine's Embrace",
    'Transfer Essence',
    'Blood of my Blood',
    //Zealot
    'Dust of Pandemonium',
    'Ritual of Lunacy',
    //Shaman
    'Gather Round',
    'Fury of Da Green',
  ];

  order = [
    { name: 'Bright Wizard', id: 11 },
    { name: 'Shadow Warrior', id: 18 },
    { name: 'Engineer', id: 4 },
    { name: 'White Lion', id: 19 },
    { name: 'Witch Hunter', id: 9 },
    { name: 'Slayer', id: 2 },
    { name: 'KOTBS', id: 10 },
    { name: 'Swordmaster', id: 17 },
    { name: 'Ironbreaker', id: 1 },
    { name: 'Warrior Priest', id: 12 },
    { name: 'Archmage', id: 20 },
    { name: 'Runepriest', id: 3 },
    { name: 'Guard', id: false },
    { name: 'ability', id: false },
  ];

  destro = [
    { name: 'Sorcerer', id: 24 },
    { name: 'Witch Elf', id: 22 },
    { name: 'Black Guard', id: 21 },
    { name: 'DoK', id: 23 },
    { name: 'Marauder', id: 14 },
    { name: 'Chosen', id: 13 },
    { name: 'Magus', id: 16 },
    { name: 'Zealot', id: 15 },
    { name: 'Black Orc', id: 5 },
    { name: 'Choppa', id: 6 },
    { name: 'Shaman', id: 7 },
    { name: 'Squig Herder', id: 8 },
    { name: 'Guard', id: false },
    { name: 'ability', id: false },
  ];

  enemies = [];

  constructor(
    private uploadService: UploadService,
    private parseService: ParseService
  ) {
    // Create 100 users
    const users = Array.from({ length: 100 }, (_, k) => createNewUser(k + 1));

    // Assign the data to the data source for the table to render
    //this.dataSourceX = new MatTableDataSource(users);
    //this.dataSource = new MatTableDataSource({});
  }
  ngAfterViewInit() {
    setTimeout(() => {
      //this.dataSource.paginator = this.paginator;
      //this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    this.charName = localStorage.getItem('char');
    this.charRealm = localStorage.getItem('realm');
  }

  selectFile(event) {
    localStorage.setItem('char', this.charName);
    localStorage.setItem('realm', this.charRealm);
    this.loading = true;
    this.ready = false;
    this.file = event.target.files[0];
    this.fights = [];
    if (this.charRealm == 'order') {
      this.enemies = this.destro;
    } else {
      this.enemies = this.order;
    }
    this.checkFile(this.file);
  }

  checkFile(file) {
    this.readFile(this.file);
    //this.uploadFile(file);
  }

  uploadFile(file) {
    this.uploadService.upload(file).subscribe((data) => {
      //pass
    });
  }

  readFile(file) {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      let lines = (<string>fileReader.result).split('\n');
      var nLines = lines.length;
      var count = 0;
      this.totals = {
        mitigated: 0,
        absorbed: 0,
        incdamage: 0,
      };
      //reset data
      this.allAttackers = {};
      this.deathblows = {};
      this.deathblowAbility = {};
      this.abilityData = {};
      this.abilityUsed = [];
      lines.forEach((line) => {
        this.doCareer(line);
      });
      lines.forEach((line) => {
        this.parseLine(line);
        count += 1;
        if (count == nLines) {
          //push the last fight to data
          let sortedHealData = []; //this.sortHealData(this.allHealedData)
          this.fights.push({
            fightData: this.fightData,
            allHealed: sortedHealData,
            careerData: this.careerData,
            careerDataOut: this.careerDataOut,
            fightInfo: this.fightInfo,
            abilityData: this.abilityData,
            abilityUsed: this.abilityUsed,
          });
        }
      });
      this.fights.forEach((fight) => {
        fight['fightData'].forEach((timeStamp) => {
          if (timeStamp['outDmg'].length > 0) {
            let ability_score = {};
            let ability_targets = {};
            timeStamp['outDmg'].forEach((hit) => {
              if (hit.ability in ability_score) {
                ability_score[hit.ability]['dmg'] += hit.dmg;
                if (ability_targets[hit.ability].includes(hit.target)) {
                  //hit same guy again in 1s
                } else {
                  ability_score[hit.ability]['count'] += 1;
                  ability_targets[hit.ability].push(hit.target);
                }
              } else {
                ability_score[hit.ability] = { dmg: hit.dmg, count: 1 };
                ability_targets[hit.ability] = [hit.target];
              }
            });

            let new_damgages = [];
            for (let key in ability_score) {
              let value = ability_score[key];
              new_damgages.push({
                ability: key,
                dmg: value['dmg'] / value['count'],
                crit: 'x',
              });
            }
            timeStamp['outDmgAvg'] = new_damgages;
          }
        });
      });
      this.loading = false;

      //console.log(this.fights)
      //console.log(this.allAttackers)
      this.dbList = [] = Object.keys(this.deathblows).map((key) => ({
        name: key,
        dbs: this.deathblows[key]['dbs'],
        died: this.deathblows[key]['died'],
        kdiff: this.deathblows[key]['dbs'] - this.deathblows[key]['died'],
        career: this.getCareer(key),
      }));
      this.sortDbList = this.dbList.sort((n1, n2) => {
        if (n1.kdiff > n2.kdiff) {
          return -1;
        }
        if (n1.kdiff < n2.kdiff) {
          return 1;
        }
        return 0;
      });
      //console.log(this.sortDbList);
      /*
      this.dbAbilityList = Object.keys(this.deathblowAbility).map(key => ({
        name: key,
        dbs : this.deathblowAbility[key]['dbs']
      }));
      this.sortDBability =  this.dbAbilityList.sort((n1,n2) => {
        if (n1.dbs > n2.dbs) {
            return -1;
        }
        if (n1.dbs < n2.dbs) {
            return 1;
        }
        return 0;
      });
      */
      //console.log(this.sortDBability)
      this.dataSource = new MatTableDataSource(this.sortDbList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.ready = true;
    };
    fileReader.readAsText(this.file);
  }

  getCareer(name) {
    return this.allAttackers[name];
  }

  careerIcon(career) {
    if (career) {
      career = career.toLowerCase();
      career = career.replace(/ /g, '-');
      return 'assets/images/icons/' + career + '.png';
    } else {
    }
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
    if (typeof FileReader !== 'undefined') {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        //this.srcResult = e.target.result;
      };
      reader.readAsArrayBuffer(inputNode.files[0]);
    }
  }

  sortHealData(data) {
    let allHealedList = ([] = Object.keys(data).map((key) => ({
      name: key,
      healed: this.allHealedData[key]['healed'],
      overhealed: this.allHealedData[key]['overhealed'],
      total_healed: this.allHealedData[key]['total_healed'],
      overheal_percent: Math.round(
        (100 * this.allHealedData[key]['overhealed']) /
          this.allHealedData[key]['total_healed']
      ),
      career: this.getCareer(key),
    })));
    let sortedHealData = allHealedList.sort((n1, n2) => {
      if (n1.healed > n2.healed) {
        return -1;
      }
      if (n1.healed < n2.healed) {
        return 1;
      }
      return 0;
    });
    return sortedHealData;
  }

  gap(diff) {
    return Array(diff)
      .fill(0)
      .map((x, i) => i);
  }

  barHeight(dmg) {
    let style = dmg / 15;
    return style;
  }

  myTooltip(hit) {
    if (hit.attacker && hit.career) {
      return hit.line + ' (' + hit.career['name'] + ')';
    } else if ('dmg' in hit) {
      return hit.line;
    } else {
      return hit.str; //healings
    }
  }

  inSearch(i, hit) {
    if (!this.fightSearch[i]) {
      return true;
    }
    if (hit.ability == 'Guard') {
      hit.attacker = 'Guard';
    }
    if (hit.ability == 'ability') {
      hit.attacker = 'Guard';
    }
    if (hit.attacker && this.fightSearch[i]) {
      if (this.fightSearch[i].indexOf('-') > -1) {
        if (hit.ability.indexOf(this.fightSearch[i].replace('-', '')) == -1) {
          return true;
        }
      } else if (
        hit.attacker.indexOf(this.fightSearch[i]) > -1 ||
        hit.ability.indexOf(this.fightSearch[i]) > -1
      ) {
        return true;
      } else if ('name' in hit.career) {
        if (hit.career.name.indexOf(this.fightSearch[i]) > -1) {
          return true;
        }
      }
    }
  }

  sumIncDmg(timestamp, i) {
    var sum = 0;
    timestamp.incDmg.forEach((el) => {
      if (!isNaN(el.dmg)) {
        if (el.ability == 'Guard') {
          el.attacker = 'Guard';
        }
        if (el.ability == 'ability') {
          el.attacker = 'Guard';
        }
        if (el.attacker) {
          if (this.fightSearch[i]) {
            if (this.fightSearch[i].indexOf('-') > -1) {
              if (
                el.ability.indexOf(this.fightSearch[i].replace('-', '')) == -1
              ) {
                sum += el.dmg;
              }
            } else if (
              el.attacker.indexOf(this.fightSearch[i]) > -1 ||
              el.ability.indexOf(this.fightSearch[i]) > -1
            ) {
              sum += el.dmg;
            } else if ('name' in el.career) {
              if (el.career.name.indexOf(this.fightSearch[i]) > -1) {
                sum += el.dmg;
              }
            }
          } else {
            sum += el.dmg;
          }
        }
      }
    });
    if (sum > 0) {
      return sum;
    }
  }

  outSearch(i, hit) {
    if (!this.fightSearchOut[i]) {
      return true;
    }
    //if(hit.ability == "Guard") {hit.attacker = 'Guard'}

    console.log(hit);
    if (hit.target && this.fightSearchOut[i]) {
      if (hit.ability.indexOf(this.fightSearchOut[i]) > -1) {
        return true;
      }
      if (hit.target.indexOf(this.fightSearchOut[i]) > -1) {
        return true;
      }
    }
  }

  healSearch(i, hit) {
    if (!this.fightSearchHeal[i]) {
      return true;
    }
    //if(hit.ability == "Guard") {hit.attacker = 'Guard'}
    console.log(i, hit, this.fightSearchHeal[i]);
    if (this.fightSearchHeal[i]) {
      console.log(hit.who.indexOf(this.fightSearchHeal[i]));
      if (hit.who.indexOf(this.fightSearchHeal[i]) > -1) {
        return true;
      }
      if (hit.ability.indexOf(this.fightSearchHeal[i]) > -1) {
        return true;
      }
    }
  }

  sumIncHeal(timestamp) {
    var sum = 0;
    timestamp.incHeal.forEach((el) => {
      if (!isNaN(el.value)) {
        sum += el.value;
      }
    });
    if (sum > 0) {
      return sum;
    }
  }

  getRealm(career) {
    let orders = [
      'Bright Wizard',
      'Shadow Warrior',
      'Engineer',
      'White Lion',
      'Witch Hunter',
      'Slayer',
      'KOTBS',
      'Swordmaster',
      'Ironbreaker',
      'Archmage',
      'Runepriest',
    ];
    if (orders.includes(career)) {
      return 'order';
    } else {
      return 'destro';
    }
  }

  sumOutDmg(timestamp, i) {
    var sum = 0;
    timestamp.outDmg.forEach((el) => {
      if (!isNaN(el.dmg)) {
        if (this.fightSearchOut[i]) {
          if (el.ability.indexOf(this.fightSearchOut[i]) > -1) {
            sum += el.dmg;
          }
          if (el.target.indexOf(this.fightSearchOut[i]) > -1) {
            sum += el.dmg;
          }
        } else {
          sum += el.dmg;
        }
      }
    });
    if (sum > 0) {
      return sum;
    }
  }
  sumOutDmgAvg(timestamp) {
    var sum = 0;
    timestamp.outDmgAvg.forEach((el) => {
      if (!isNaN(el.dmg)) {
        sum += el.dmg;
      }
    });
    if (sum > 0) {
      return Math.round(sum);
    }
  }
  avgSingleTarget(damages, index) {
    if (this.avgSingle[index]) {
      let ability_score = {};

      damages.forEach((hit) => {
        if (hit.ability in ability_score) {
          ability_score[hit.ability]['dmg'] += hit.dmg;
          ability_score[hit.ability]['count'] += 1;
        } else {
          ability_score[hit.ability] = { dmg: hit.dmg, count: 1 };
        }
      });

      let new_damgages = [];
      for (let key in ability_score) {
        let value = ability_score[key];
        new_damgages.push({
          ability: key,
          dmg: value['dmg'] / value['count'],
          crit: 'x',
        });
      }
      return new_damgages;
    } else {
      return damages;
    }
  }

  peakIn(fight) {
    var index = 0;
    var burst = 0;
    var fightLength = fight.length;
    fight.forEach((timestamp) => {
      if (index + 2 < fightLength) {
        var tdmg1 = this.sumIncDmg(fight[index], false);
        var tdmg2 = this.sumIncDmg(fight[index + 1], false);
        var tdmg3 = this.sumIncDmg(fight[index + 2], false);
        if (isNaN(tdmg1)) {
          tdmg1 = 0;
        }
        if (isNaN(tdmg2)) {
          tdmg2 = 0;
        }
        if (isNaN(tdmg3)) {
          tdmg3 = 0;
        }
        var sumdmg = tdmg1 + tdmg2 + tdmg3;
        if (sumdmg > burst) {
          burst = sumdmg;
        }
      }
      index += 1;
    });
    return burst;
  }

  peakOut(fight) {
    var index = 0;
    var burst = 0;
    var fightLength = fight.length;
    fight.forEach((timestamp) => {
      if (index + 2 < fightLength) {
        var tdmg1 = this.sumOutDmg(fight[index], 0);
        var tdmg2 = this.sumOutDmg(fight[index + 1], 0);
        var tdmg3 = this.sumOutDmg(fight[index + 2], 0);
        if (isNaN(tdmg1)) {
          tdmg1 = 0;
        }
        if (isNaN(tdmg2)) {
          tdmg2 = 0;
        }
        if (isNaN(tdmg3)) {
          tdmg3 = 0;
        }
        var sumdmg = tdmg1 + tdmg2 + tdmg3;
        if (sumdmg > burst) {
          burst = sumdmg;
        }
      }
      index += 1;
    });
    return burst;
  }
  isDmg(value) {
    if (isNaN(value)) {
      return false;
    } else {
      return true;
    }
  }

  getCareerDmg(data, career) {
    if (data[career]['hits'] > 0) {
      return data[career]['dmg'];
    }
  }

  getCareerAbs(data, career) {
    if (data[career]['hits'] > 0) {
      return data[career]['absorbed'];
    }
  }

  getCareerMit(data, career) {
    if (data[career]['hits'] > 0) {
      return (
        Math.round(
          (data[career]['mitigated'] /
            (data[career]['dmg'] + data[career]['mitigated'])) *
            100
        ) + '%'
      );
    }
  }

  getCareerChars(data, career) {
    if (data[career]['hits'] > 0 && career != 'Guard') {
      return data[career]['chars'].length;
    }
  }
  getCareerCharsTooltip(data, career) {
    if (career in data) {
      if (data[career]['hits'] > 0) {
        return data[career]['chars'];
      }
    }
  }

  getCareerAv(data, career) {
    if (data[career]['hits'] > 0) {
      return (
        Math.round((data[career]['avoidance'] / data[career]['hits']) * 100) +
        '%'
      );
    }
  }
  getCareerBlocked(data, career) {
    if (data[career]['hits'] > 0) {
      return (
        Math.round((data[career]['blocked'] / data[career]['hits']) * 100) + '%'
      );
    }
  }

  getCareerParry(data, career) {
    if (data[career]['hits'] > 0) {
      return (
        Math.round((data[career]['parried'] / data[career]['hits']) * 100) + '%'
      );
    }
  }

  getCareerDisrupt(data, career) {
    if (data[career]['hits'] > 0) {
      return (
        Math.round((data[career]['disrupted'] / data[career]['hits']) * 100) +
        '%'
      );
    }
  }

  getCareerDodge(data, career) {
    if (data[career]['hits'] > 0) {
      return (
        Math.round((data[career]['dodged'] / data[career]['hits']) * 100) + '%'
      );
    }
  }

  getCareerHits(data, career) {
    if (data[career]['hits'] > 0) {
      return data[career]['succ_hits'];
    }
  }

  getCareerAttacks(data, career) {
    if (data[career]['hits'] > 0) {
      return data[career]['hits'];
    }
  }

  getCareerCrits(data, career) {
    if (data[career]['hits'] > 0 && data[career] != 'Guard') {
      return data[career]['crits'];
    }
  }

  avoidance(fight) {
    var thits = 0;
    var tavoids = 0;
    var tblocks = 0;
    fight.fightData.forEach((t) => {
      thits += t.hits;
      if (!isNaN(t.blocks)) {
        tavoids += t.blocks;
        tblocks += t.blocks;
      }
      if (!isNaN(t.parries)) {
        tavoids += t.parries;
      }
      if (!isNaN(t.dodge)) {
        tavoids += t.dodge;
      }
      if (!isNaN(t.disrupt)) {
        tavoids += t.disrupt;
      }
    });

    return (
      Math.round((tavoids / thits) * 100) +
      '% (Blocked: ' +
      Math.round((tblocks / thits) * 100) +
      '%)'
    );
  }

  critsIn(fightInfo) {
    if (fightInfo.hitsIn > 0) {
      return Math.round((fightInfo.critsIn / fightInfo.hitsIn) * 100) + '%';
    }
  }

  critsOut(fightInfo) {
    if (fightInfo.hitsOut > 0) {
      return Math.round((fightInfo.critsOut / fightInfo.hitsOut) * 100) + '%';
    }
  }

  doCareer(line) {
    let lineArray = line.split(']');
    if (lineArray.length == 3) {
      var combatStr = lineArray[2].substring(1);
      var combatStrArray = combatStr.split(' ');
      var abilityIndexStart = 0;
      var abilityIndexEnd = 0;
      let attacker = '';
      let ability = '';
      let i = 0;
      if (combatStr.indexOf('You ') == -1) {
        if (combatStr.indexOf(' has been ') == -1) {
          combatStrArray.forEach((el) => {
            if (el.indexOf("'s") > -1) {
              if (abilityIndexStart == 0) {
                abilityIndexStart = i + 1;
                attacker = el.slice(0, -2);
              }
            }
            if (el == 'critically') {
              abilityIndexEnd = i - 1;
            }
            if (abilityIndexEnd == 0 && el == 'hits') {
              abilityIndexEnd = i - 1;
            }
            if (abilityIndexEnd == 0 && el == 'heals') {
              abilityIndexEnd = i - 1;
            }
            i += 1;
          });
        } else {
          combatStrArray.forEach((el) => {
            if (el.indexOf("'s") > -1) {
              if (abilityIndexStart == 0) {
                abilityIndexStart = i + 1;
                attacker = el.slice(0, -2);
              }
            }
            if (abilityIndexEnd == 0 && el == 'in') {
              abilityIndexEnd = i - 1;
            }
            i += 1;
          });
        }
      }

      if (abilityIndexStart > 0) {
        ability += combatStrArray[abilityIndexStart];
        if (abilityIndexEnd == abilityIndexStart + 1) {
          ability += ' ' + combatStrArray[abilityIndexEnd];
        } else if (abilityIndexEnd == abilityIndexStart + 2) {
          ability +=
            ' ' +
            combatStrArray[abilityIndexEnd - 1] +
            ' ' +
            combatStrArray[abilityIndexEnd];
        } else if (abilityIndexEnd == abilityIndexStart + 3) {
          ability +=
            ' ' +
            combatStrArray[abilityIndexEnd - 2] +
            ' ' +
            combatStrArray[abilityIndexEnd - 1] +
            ' ' +
            combatStrArray[abilityIndexEnd];
        }
      }

      let sharedAbilities = [
        'Prayer of Devotion',
        'Prayer of Righteousness',
        'Flames of Rhuin',
        'Flame Shield',
        'Frozen Touch',
        'Daemonic Chill',
        'Dissipating Energies',
        'Covenant of Celerity',
        'Covenant of Vitality',
        'Corrupting Retribution',
      ];

      if (attacker && ability) {
        if (ability in abilityList[0]) {
          if (abilityList[0][ability].length == 1) {
            if (!sharedAbilities.includes(ability)) {
              this.allAttackers[attacker] =
                abilityList[0][ability][0]['career']['name'];
            }
          }
        }

        if (combatStr.indexOf(' heals you') > -1) {
          if (this.destGroupHeals.includes(ability)) {
            // "attacker" in my group
          }
        }
      }
    }
  }

  parseLine(line) {
    let lineArray = line.split(']');
    var dmg: any = false;
    var newTimeStamp = true;

    if (lineArray.length == 3) {
      //check 's
      let test = line.split("'s");
      // TIME
      let dateStr = lineArray[0].substring(1).split('/');
      var timeStr = lineArray[1].substring(1).split(':');
      var timeKey = lineArray[1].substring(1);
      var dateKey = lineArray[0].substring(1);
      var timestamp = this.setTime(dateStr, timeStr);
      // COMBAT
      var combatStr = lineArray[2].substring(1);
      var combatStrArray = combatStr.split(' ');
      var msg: any = false;
      var event: any = false;
      var outDmg: any = false;
      var incHeal: any = false;
      var outHeal: any = false;
      var selfHeal: any = false;
      var incDmg: any = false;
      var block = 0;
      var parry = 0;
      var dodge = 0;
      var disrupt = 0;
      var crit = '';
      var died = 0;
      var kill = 0;
      var assist = 0;
      var no_ap = 0;
      var overheal = 0;
      var category = '';
      var aoe = '';
      var duration = '';

      var ability = '';
      var attacker: any = false;
      var career: any = {};

      var parryYou = 0;
      var dodgeYou = 0;
      var disruptYou = 0;
      var blockYou = 0;
      var avoidYou = 0;

      var mitigated = 0;
      var absorbed = 0;

      var target: any = false;
      var targetClass: any = false;

      var whoDead: any = false;

      // your attacks avoided
      if (
        combatStr.indexOf(' your Guard') == -1 ||
        combatStr.indexOf(' your ability') == -1
      ) {
        if (combatStr.indexOf(' parried your ') > -1) {
          parryYou = 1;
          avoidYou = 1;
        }
        if (combatStr.indexOf(' blocked your ') > -1) {
          blockYou = 1;
          avoidYou = 1;
        }
        if (combatStr.indexOf(' dodged your ') > -1) {
          dodgeYou = 1;
          avoidYou = 1;
        }
        if (combatStr.indexOf(' disrupted your ') > -1) {
          disruptYou = 1;
          avoidYou = 1;
        }

        if (avoidYou == 1) {
          var i = 0;
          ability = '';
          var startAbility = false;
          combatStrArray.forEach((el) => {
            if (startAbility) {
              ability += el + ' ';
            }
            if (el == 'your') {
              startAbility = true;
            }
            i += 1;
          });
          target = combatStrArray[0];
          targetClass = this.allAttackers[target];
          ability = ability.slice(0, -3);
          outDmg = 'avoidYou';
        }
      }

      if (combatStr.indexOf(' hits you ') > -1) {
        msg = 'inc dmg';
        var i = 0;
        var dmgIndex = 0;
        var abilityIndexStart = 0;
        var abilityIndexEnd = 0;

        combatStrArray.forEach((el) => {
          if (el == 'for') {
            dmgIndex = i;
          }
          if (el.indexOf("'s") > -1) {
            if (abilityIndexStart == 0) {
              abilityIndexStart = i + 1;
            }
            attacker = el.slice(0, -2);
            if (attacker in this.allAttackers) {
              //pass
            } else {
              //this.allAttackers[attacker] = {career:"Unknown"}
            }
          }
          if (el == 'critically') {
            abilityIndexEnd = i - 1;
            crit = 'crit';
          }
          if (abilityIndexEnd == 0 && el == 'hits') {
            abilityIndexEnd = i - 1;
          }
          if (el == 'Guard' || el == 'ability') {
            ability = 'Guard';
            career = { name: 'Guard' };
          }
          if (el.indexOf('mitigated') > -1) {
            mitigated = combatStrArray[i - 1].replace('(', '') * 1;
          }
          if (el.indexOf('absorbed') > -1) {
            absorbed = combatStrArray[i - 1].replace('(', '') * 1;
          }
          i += 1;
        });

        this.totals['mitigated'] += mitigated;
        this.totals['absorbed'] += absorbed;

        if (abilityIndexStart > 0) {
          ability += combatStrArray[abilityIndexStart];
          if (abilityIndexEnd == abilityIndexStart + 1) {
            ability += ' ' + combatStrArray[abilityIndexEnd];
          } else if (abilityIndexEnd > abilityIndexStart + 1) {
            ability +=
              ' ' +
              combatStrArray[abilityIndexEnd - 1] +
              ' ' +
              combatStrArray[abilityIndexEnd];
          }
        }
        incDmg = combatStrArray[dmgIndex + 1] * 1 + 0.01;

        if (isNaN(incDmg)) {
          combatStrArray[dmgIndex - 1];
        }
        this.totalDmg += dmg * 1;
        this.totalHits += 1;
      } else if (combatStr.indexOf('Your ') > -1) {
        //my healing
        if (combatStr.indexOf(' heals you ') > -1) {
          var healIndex = 0;
          var i = 0;
          combatStrArray.forEach((el) => {
            if (el == 'for') {
              healIndex = i;
            }
            i += 1;
          });
          incHeal = combatStrArray[healIndex + 1] * 1 + 0.1;
          selfHeal = 'selfHeal';
        } else if (combatStr.indexOf(' hits ') > -1) {
          var i = 0;
          var dmgIndex = 0;
          var abilityIndexStart = 0;
          var abilityIndexEnd = 0;
          var critIndex = 0;
          var hitIndex = 0;

          combatStrArray.forEach((el) => {
            if (el == 'for') {
              dmgIndex = i;
            }
            if (el == 'critically') {
              critIndex = i;
              crit = 'crit';
            }
            if (el == 'hits') {
              hitIndex = i;
            }
            if (i > 0 && critIndex == 0 && hitIndex == 0) {
              if (i == 1) {
                ability += el;
              } else {
                ability += ' ' + el;
              }
            }
            if (el.indexOf('mitigated') > -1) {
              mitigated = combatStrArray[i - 1].replace('(', '') * 1;
            }
            if (el.indexOf('absorbed') > -1) {
              absorbed = combatStrArray[i - 1].replace('(', '') * 1;
            }
            i += 1;
          });
          target = combatStrArray[hitIndex + 1];
          targetClass = this.allAttackers[combatStrArray[hitIndex + 1]];
          outDmg = combatStrArray[dmgIndex + 1] * 1 + 0.01;
        }
      }

      if (combatStr.indexOf(' heals you ') > -1) {
        if (combatStr.indexOf('Your ') == -1) {
          var healIndex = 0;
          var i = 0;
          var healStart = 0;
          var healEnd = 0;
          combatStrArray.forEach((el) => {
            if (el == 'for') {
              healIndex = i;
            }
            if (el.indexOf("'s") > -1) {
              if (healStart == 0) {
                healStart = i + 1;
              }
              attacker = el.slice(0, -2); //healer
            }
            if (el == 'critically') {
              healEnd = i - 1;
            }
            if (healEnd == 0 && el == 'hits') {
              healEnd = i - 1;
            }
            if (healEnd == 0 && el == 'heals') {
              healEnd = i - 1;
            }
            i += 1;
          });
          if (healStart > 0) {
            ability += combatStrArray[healStart];

            if (healEnd == healStart + 1) {
              ability += ' ' + combatStrArray[healEnd];
            } else if (healEnd > healStart + 1) {
              ability +=
                ' ' +
                combatStrArray[healEnd - 1] +
                ' ' +
                combatStrArray[healEnd];
            }
          }
          incHeal = combatStrArray[healIndex + 1] * 1;
        }
      }

      //parry
      if (combatStr.indexOf('You parried ') > -1) {
        if (combatStr.indexOf('You parried your ') > -1) {
          incDmg = 'guard parry';
        } else {
          incDmg = 'parry';
        }
        this.totalParry += 1;
        parry += 1;
      }
      //block
      if (combatStr.indexOf('You blocked ') > -1) {
        if (combatStr.indexOf('You blocked your ') > -1) {
          incDmg = 'guard block';
        } else {
          incDmg = 'block';
        }
        this.totalBlock += 1;
        block += 1;
      }
      //dodge
      if (combatStr.indexOf('You dodged ') > -1) {
        if (combatStr.indexOf('You dodged your ') > -1) {
          incDmg = 'guard dodge';
        } else {
          incDmg = 'dodge';
        }
        //this.totalDodge += 1
        dodge += 1;
      }
      //disrupt
      if (combatStr.indexOf('You disrupted ') > -1) {
        if (combatStr.indexOf('You disrupted your ') > -1) {
          incDmg = 'guard disrupt';
        } else {
          incDmg = 'disrupt';
        }
        //this.totalDodge += 1
        disrupt += 1;
      }
      if (disrupt + dodge + block + parry > 0) {
        var logAbility = false;
        combatStrArray.forEach((el) => {
          if (logAbility) {
            ability += el + ' ';
          }
          if (el.indexOf("'s") > -1) {
            logAbility = true;
            attacker = el.slice(0, -2);
          }
        });

        ability = ability.slice(0, -3);
        var guards = [
          'guard block',
          'guard parry',
          'guard disrupt',
          'guard dodge',
        ];
        if (guards.includes(incDmg)) {
          ability = 'Guard';
        }
      }

      //involved
      /*
      if(avoidYou || incDmg || incHeal || outDmg || assist || died || kill) {
        console.log(combatStr, attacker, target)
      }
      */

      //all deathblows/ deaths
      let dbLogged = false;
      let dbAbility = '';
      let dbAbilityLogging = false;
      let dbAbilityLogged = false;
      let dbi = 0;
      if (combatStr.indexOf(' has been ') > -1) {
        combatStrArray.forEach((el) => {
          if (dbLogged) {
            if (el == 'in') {
              dbAbilityLogged = true;
            }
            if (!dbAbilityLogged) {
              dbAbility += el + ' ';
            }
          }
          if (el.indexOf("'s") > -1) {
            if (!dbLogged) {
              attacker = el.slice(0, -2);
              if (attacker in this.deathblows) {
                this.deathblows[attacker]['dbs'] += 1;
              } else {
                this.deathblows[attacker] = { dbs: 1, died: 0 };
              }
              dbLogged = true;
            }
          }
          dbi += 1;
        });
        dbAbility = dbAbility.slice(0, -1);
        if (combatStr.indexOf(' killed by Guard') > -1) {
          dbAbility = 'Guard';
        }
        if (combatStr.indexOf(' killed by falling') > -1) {
          dbAbility = 'Falling';
        }
        if (dbAbility == '') {
          //console.log(combatStr)
        }

        if (dbAbility in this.deathblowAbility) {
          this.deathblowAbility[dbAbility]['dbs'] += 1;
        } else {
          this.deathblowAbility[dbAbility] = { dbs: 1 };
        }
        whoDead = combatStrArray[0];
        if (whoDead in this.deathblows) {
          this.deathblows[whoDead]['died'] += 1;
        } else {
          this.deathblows[whoDead] = { dbs: 0, died: 1 };
        }
      }

      //my deaths
      var char_str = this.charName + ' has been ';
      if (combatStr.indexOf(char_str) > -1) {
        died = 1;
        incDmg = 'died';
      }

      //my deathblows / kills
      var char_str = ' by ' + this.charName;
      if (combatStr.indexOf(char_str) > -1) {
        kill = 1;
      }

      //assists
      if (combatStr.indexOf('renown from assisting') > -1) {
        assist = 1;
      }

      //actionpoints
      if (combatStr.indexOf('Not enough Action Points') > -1) {
        no_ap = 1;
      }

      if (attacker in this.allAttackers) {
        career = { name: this.allAttackers[attacker] };
        if (ability in abilityList[0]) {
          category = abilityList[0][ability][0]['category'];
          aoe = abilityList[0][ability][0]['aoe'];
          duration = abilityList[0][ability][0]['duration'];
        }
      } else {
        if (ability in abilityList[0]) {
          if (abilityList[0][ability].length > 1 && ability != 'Guard') {
            if (attacker in this.allAttackers) {
              if (this.allAttackers[attacker]['career'] != 'Unknown') {
                career['name'] = this.allAttackers[attacker]['career'];
              }
            } else {
              career = { name: 'Unknown' };
              (category = 'Unknown'), (aoe = 'Unknown');
              duration = 'Unknown';
            }
          } else {
            career = abilityList[0][ability][0]['career'];
            category = abilityList[0][ability][0]['category'];
            aoe = abilityList[0][ability][0]['aoe'];
            duration = abilityList[0][ability][0]['duration'];

            if (attacker in this.allAttackers) {
              this.allAttackers[attacker]['career'] = career.name;
            }
          }
        }
      }

      if (ability == 'attack') {
        career = { name: 'Auto Attack' };
      } else if (career == '') {
        career = { name: 'Unknown' };
      }
      if (ability == 'Guard' || ability == 'ability') {
        career = { name: 'Guard' };
      }

      if (!this.fights) {
      }
      var newFight = false;

      if (this.lastTime && (incDmg || outDmg || incHeal)) {
        var timeDiff = Math.round(
          (timestamp.getTime() - this.lastTime.getTime()) / 1000
        );
        if (timeDiff > 20) {
          let sortedHealData = []; //this.sortHealData(this.allHealedData)
          this.fights.push({
            fightData: this.fightData,
            allHealed: sortedHealData,
            careerData: this.careerData,
            careerDataOut: this.careerDataOut,
            fightInfo: this.fightInfo,
            abilityData: this.abilityData,
            abilityUsed: this.abilityUsed,
          });
          var newFight = true;
          timeDiff = 0;
        }
        if (timeDiff >= 1 || newFight) {
          newTimeStamp = true;
        } else {
          newTimeStamp = false;
        }
      }

      if (incDmg || outDmg || incHeal) {
        this.lastTime = timestamp;
      }

      if (!this.startTime) {
        this.startTime = timestamp;
        newTimeStamp = true;
        var newFight = true;
      }

      if (newFight) {
        this.fightData = [];
        this.allHealedData = [];
        this.abilityData = {};
        this.abilityUsed = [];
        this.fightInfo = {
          totalInc: 0,
          totalOut: 0,
          critsIn: 0,
          critsOut: 0,
          hitsIn: 0,
          hitsOut: 0,
          died: 0,
          kills: 0,
          assists: 0,
          no_ap: 0,
          totalIncHeal: 0,
          totalIncHealSelf: 0,
          incmitigation: 0,
          incabsorbed: 0,
          opponents: 0,
        };
        this.careerData = {
          Slayer: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
            dps: true,
          },
          'Witch Hunter': {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
            dps: true,
          },
          'White Lion': {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
            dps: true,
          },
          'Bright Wizard': {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
            dps: true,
          },
          'Shadow Warrior': {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
            dps: true,
          },
          Engineer: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
            dps: true,
          },
          KOTBS: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Swordmaster: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Ironbreaker: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Archmage: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Runepriest: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          'Warrior Priest': {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Unknown: {
            dmg: 0,
            hits: 0,
            succ_hits: 0,
            crits: 0,
            avoidance: 0,
            mitigated: 0,
            blocked: 0,
            absorbed: 0,
            chars: [],
          },
          Guard: {
            dmg: 0,
            crits: 0,
            succ_hits: 0,
            hits: 0,
            avoidance: 0,
            mitigated: false,
            absorbed: false,
            blocked: 0,
            parried: 0,
            disrupted: 0,
            dodged: 0,
            chars: [],
          },
          ability: {
            dmg: 0,
            crits: 0,
            succ_hits: 0,
            hits: 0,
            avoidance: 0,
            mitigated: false,
            absorbed: false,
            blocked: 0,
            parried: 0,
            disrupted: 0,
            dodged: 0,
            chars: [],
          },
          Sorcerer: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
            dps: true,
          },
          'Witch Elf': {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
            dps: true,
          },
          'Black Guard': {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          DoK: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Choppa: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
            dps: true,
          },
          'Black Orc': {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Shaman: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          'Squig Herder': {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
            dps: true,
          },
          Chosen: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Marauder: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
            dps: true,
          },
          Zealot: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Magus: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
            dps: true,
          },
        };
        this.careerDataOut = {
          Slayer: {
            dmg: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          'Witch Hunter': {
            dmg: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          'White Lion': {
            dmg: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          'Bright Wizard': {
            dmg: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          'Shadow Warrior': {
            dmg: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Engineer: {
            dmg: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          KOTBS: {
            dmg: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Swordmaster: {
            dmg: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Ironbreaker: {
            dmg: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Archmage: {
            dmg: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Runepriest: {
            dmg: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          'Warrior Priest': {
            dmg: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Unknown: {
            dmg: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          ability: {
            dmg: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Sorcerer: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          'Witch Elf': {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          'Black Guard': {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          DoK: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Choppa: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          'Black Orc': {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Shaman: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          'Squig Herder': {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Chosen: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Marauder: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Zealot: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
          Magus: {
            dmg: 0,
            crits: 0,
            hits: 0,
            succ_hits: 0,
            avoidance: 0,
            mitigated: 0,
            absorbed: 0,
            blocked: 0,
            chars: [],
          },
        };
        this.allHealedData = [];
      }

      //empty timestamps
      if (timeDiff > 1) {
        // empty timestamps
        for (let i = 2; i <= timeDiff; i++) {
          this.fightData.push({
            date: '',
            timestamp: 'blank',
            incDmg: [],
            outDmg: [],
            outDmgAvg: [],
            incHeal: [],
            kills: 0,
            assists: 0,
            no_ap: 0,
            overheal: 0,
            deaths: 0,
            timeDiff: timeDiff,
            totalInc: 0,
            totalOut: 0,
            hits: 0,
            blocks: 0,
            parries: 0,
            disrupt: 0,
            dodge: 0,
            avoids: 0,
            opponents: 0,
          });
        }
      }

      if (assist == 1) {
        this.fightInfo.assists += 1;
      }
      if (kill == 1) {
        this.fightInfo.kills += 1;
      }
      //incoming damage
      this.fightInfo.incmitigation += mitigated;
      this.fightInfo.incabsorbed += absorbed;
      if (incDmg) {
        if (isNaN(incDmg)) {
          var isValue = false;
          if (died > 0) {
            this.fightInfo.died += 1;
          }
        } else {
          var isValue = true;
          incDmg = Math.round(incDmg);
          this.fightInfo.totalInc += incDmg;
          if (ability != 'Guard') {
            this.fightInfo.hitsIn += 1;
          }
          if (crit == 'crit') {
            this.fightInfo.critsIn += 1;
          }

          this.totals['incdamage'] += incDmg;
        }

        if (newTimeStamp) {
          this.fightData.push({
            date: dateKey,
            timestamp: timeKey,
            incDmg: [
              {
                ability: ability,
                career: career,
                dmg: incDmg,
                attacker: attacker,
                crit: crit,
                category: category,
                aoe: aoe,
                duration: duration,
                line: line,
              },
            ],
            outDmg: [],
            outDmgAvg: [],
            incHeal: [],
            kills: 0,
            assists: 0,
            no_ap: 0,
            overheal: overheal,
            deaths: 0,
            timeDiff: timeDiff,
            hits: 1,
            blocks: block,
            parries: parry,
            disrupt: disrupt,
            dodge: dodge,
            avoids: block + parry + disrupt + dodge,
            died: died,
          });
          if (career.name in this.careerData) {
            this.careerData[career.name]['hits'] += 1;
            if (attacker) {
              if (!this.careerData[career.name]['chars'].includes(attacker)) {
                this.careerData[career.name]['chars'].push(attacker);
              }
            }
          }
          if (isValue) {
            this.fightData[0]['totalInc'] = incDmg;
            if (career.name in this.careerData) {
              this.careerData[career.name]['succ_hits'] += 1;
              this.careerData[career.name]['dmg'] += incDmg;
              if (!isNaN(absorbed)) {
                this.careerData[career.name]['absorbed'] += absorbed;
              }
              if (!isNaN(mitigated)) {
                this.careerData[career.name]['mitigated'] += mitigated;
              }
              if (crit == 'crit') {
                this.careerData[career.name]['crits'] += 1;
              }
            }
          } else {
            this.fightData[0]['totalInc'] = 0;
            if (career.name in this.careerData) {
              this.careerData[career.name]['avoidance'] +=
                block + parry + dodge + disrupt;
              this.careerData[career.name]['blocked'] += block;
              if (career.name == 'Guard') {
                this.careerData[career.name]['disrupted'] += disrupt;
                this.careerData[career.name]['dodged'] += dodge;
                this.careerData[career.name]['parried'] += parry;
              }
            }
          }
        } else {
          //same timestamp

          this.fightData[this.fightData.length - 1]['incDmg'].push({
            ability: ability,
            career: career,
            dmg: incDmg,
            attacker: attacker,
            crit: crit,
            category: category,
            aoe: aoe,
            duration: duration,
            line: line,
          });
          if (career.name in this.careerData) {
            this.careerData[career.name]['hits'] += 1;
            if (attacker) {
              if (!this.careerData[career.name]['chars'].includes(attacker)) {
                this.careerData[career.name]['chars'].push(attacker);
              }
            }
          }

          if (isValue) {
            this.fightData[this.fightData.length - 1]['totalInc'] += incDmg;

            if (career.name in this.careerData) {
              this.careerData[career.name]['dmg'] += incDmg;
              this.careerData[career.name]['succ_hits'] += 1;
              if (!isNaN(absorbed)) {
                this.careerData[career.name]['absorbed'] += absorbed;
              }
              if (!isNaN(mitigated)) {
                this.careerData[career.name]['mitigated'] += mitigated;
              }
              if (crit == 'crit') {
                this.careerData[career.name]['crits'] += 1;
              }
            }
          } else {
            if (career.name in this.careerData) {
              this.careerData[career.name]['avoidance'] +=
                block + parry + dodge + disrupt;
              this.careerData[career.name]['blocked'] += block;
              if (career.name == 'Guard') {
                this.careerData[career.name]['disrupted'] += disrupt;
                this.careerData[career.name]['dodged'] += dodge;
                this.careerData[career.name]['parried'] += parry;
              }
            }
          }
          this.fightData[this.fightData.length - 1]['hits'] += 1;
          this.fightData[this.fightData.length - 1]['blocks'] += block;
          this.fightData[this.fightData.length - 1]['died'] += died;
          this.fightData[this.fightData.length - 1]['parries'] += parry;
          this.fightData[this.fightData.length - 1]['disrupt'] += disrupt;
          this.fightData[this.fightData.length - 1]['dodge'] += dodge;
          this.fightData[this.fightData.length - 1]['avoids'] +=
            block + parry + disrupt + dodge;
        }
      }

      //outgoing damage
      if (outDmg) {
        if (isNaN(outDmg)) {
          var isValue = false;
        } else {
          var isValue = true;
          outDmg = Math.round(outDmg);
          this.fightInfo.totalOut += outDmg;
          this.fightInfo.hitsOut += 1;
          if (crit == 'crit') {
            this.fightInfo.critsOut += 1;
          }
        }

        if (ability in this.abilityData) {
          this.abilityData[ability]['hits'] += 1;
        } else {
          this.abilityData[ability] = {
            dmg: 0,
            hits: 1,
            succ_hits: 0,
            image: '',
            crits: 0,
            avoided: 0,
            blocked: 0,
            mitigated: 0,
          };
        }
        if (ability in abilityList[0]) {
          this.abilityData[ability]['image'] =
            abilityList[0][ability][0]['image'];
        } else {
          this.abilityData[ability]['image'] = false;
        }

        if (newTimeStamp) {
          this.fightData.push({
            date: dateKey,
            timestamp: timeKey,
            outDmg: [
              {
                ability: ability,
                dmg: outDmg,
                target: target,
                crit: crit,
                line: line,
                targetClass: targetClass,
              },
            ],
            outDmgAvg: [],
            incDmg: [],
            incHeal: [],
            kills: 0,
            assists: 0,
            no_ap: 0,
            overheal: overheal,
            deaths: 0,
            timeDiff: timeDiff,
            blocks: block,
            parries: parry,
            dodge: dodge,
            disrupt: disrupt,
            avoids: block + parry + dodge + disrupt,
            hits: 0,
            succ_hits: 0,
          });

          if (isValue) {
            this.fightData[0]['totalOut'] = outDmg;
          } else {
            this.fightData[0]['totalOut'] = 0;
          }
        } else {
          //same timestamp

          this.fightData[this.fightData.length - 1]['outDmg'].push({
            ability: ability,
            dmg: outDmg,
            crit: crit,
            line: line,
            target: target,
            targetClass: targetClass,
          });

          if (isValue) {
            this.fightData[this.fightData.length - 1]['totalOut'] += outDmg;
          }
          //this.fightData[this.fightData.length - 1]['blocks'] += block
          //this.fightData[this.fightData.length - 1]['parries'] += parry
        }

        if (isValue) {
          if (ability in this.abilityData) {
            this.abilityData[ability]['dmg'] += outDmg;
            this.abilityData[ability]['succ_hits'] += 1;
            this.abilityData[ability]['mitigated'] += mitigated;
          }
          if (crit == 'crit') {
            this.abilityData[ability]['crits'] += 1;
          }
        } else {
          if (blockYou == 1) {
            this.abilityData[ability]['blocked'] += 1;
          }
          this.abilityData[ability]['avoided'] += 1;
        }

        if (!this.abilityUsed.includes(ability)) {
          this.abilityUsed.push(ability);
        }

        if (targetClass in this.careerDataOut) {
          this.careerDataOut[targetClass]['hits'] += 1;
          if (isValue) {
            this.careerDataOut[targetClass]['succ_hits'] += 1;
            this.careerDataOut[targetClass]['dmg'] += outDmg;
            this.careerDataOut[targetClass]['mitigated'] += mitigated;
            this.careerDataOut[targetClass]['absorbed'] += absorbed;
          } else {
            this.careerDataOut[targetClass]['avoidance'] += 1;
            if (blockYou == 1) {
              this.careerDataOut[targetClass]['blocked'] += 1;
            }
          }
        }
      }

      //any heal
      let xhealIndex = combatStrArray.indexOf('heals');
      if (combatStr.indexOf('heals') > -1) {
        var ii = 0;
        var healAbility = '';
        combatStrArray.forEach((el) => {
          if (ii > 0) {
            if (ii < xhealIndex) {
              healAbility += el + ' ';
            }
          }
          ii += 1;
        });
        //healAbility = healAbility.slice(0, 1);

        let healTarget = combatStrArray[xhealIndex + 1];
        let healAmount = combatStrArray[xhealIndex + 3] * 1;
        if (combatStr.indexOf('overhealed') > -1) {
          var overhealAmount =
            combatStrArray[combatStrArray.length - 2].replace('(', '') * 1;
        } else {
          var overhealAmount = 0;
        }
        let totalHeal = overhealAmount + healAmount;
        if (healTarget in this.allHealedData) {
          this.allHealedData[healTarget]['healed'] += healAmount;
          this.allHealedData[healTarget]['overhealed'] += overhealAmount;
          this.allHealedData[healTarget]['total_healed'] += totalHeal;
        } else {
          this.allHealedData[healTarget] = {
            healed: healAmount * 1,
            overhealed: overhealAmount,
            total_healed: totalHeal,
            career: 'unknown',
          };
        }
        if (healTarget in this.allAttackers) {
          this.allHealedData[healTarget]['career'] =
            this.allAttackers[healTarget];
        }
      }

      //incoming heals
      if (incHeal) {
        if (combatStr.indexOf('overhealed') > -1) {
          overheal = 1;
        }
        incHeal = Math.round(incHeal);
        //overheal
        if (isNaN(incHeal)) {
          var isValue = false;
        } else {
          var isValue = true;
          this.fightInfo.totalIncHeal += incHeal;
          if (selfHeal == 'selfHeal') {
            this.fightInfo.totalIncHealSelf += incHeal;
          }
        }

        if (newTimeStamp) {
          this.fightData.push({
            date: dateKey,
            timestamp: timeKey,
            incDmg: [],
            outDmg: [],
            outDmgAvg: [],
            incHeal: [
              {
                value: incHeal,
                self: selfHeal,
                str: line,
                duration: duration,
                who: combatStrArray[0].replace("'s", ''), // healer
                ability: healAbility,
              },
            ],
            kills: 0,
            assists: 0,
            no_ap: 0,
            overheal: 0,
            deaths: 0,
            timeDiff: timeDiff,
            hits: 0,
            blocks: block,
            parries: parry,
            disrupt: disrupt,
            dodge: dodge,
            avoids: block + parry + disrupt + dodge,
            died: died,
          });

          if (isValue) {
            this.fightData[0]['totalIncHeal'] = incHeal;
            if ((selfHeal = 'selfHeal')) {
              this.fightData[0]['totalIncHealSelf'] = incHeal;
            }
          }
        } else {
          //same timestamp
          this.fightData[this.fightData.length - 1]['incHeal'].push({
            value: incHeal,
            self: selfHeal,
            str: line,
            duration: duration,
            who: combatStrArray[0].replace("'s", ''), // healer
            ability: healAbility,
          });
          if (isValue) {
            this.fightData[this.fightData.length - 1]['totalIncHeal'] +=
              incHeal;
            if ((selfHeal = 'selfHeal')) {
              this.fightData[this.fightData.length - 1]['totalIncHealSelf'] +=
                incHeal;
            }
          }
        }
      }

      //kills, deaths, assists, misc.
      if (kill + assist + died + no_ap + overheal > 0) {
        if (newTimeStamp) {
          this.fightData.push({
            date: dateKey,
            timestamp: timeKey,
            incDmg: [],
            outDmg: [],
            outDmgAvg: [],
            incHeal: [],
            kills: kill,
            assists: assist,
            no_ap: no_ap,
            overheal: overheal,
            died: died,
            timeDiff: timeDiff,
            hits: 0,
            blocks: block,
            parries: parry,
            disrupt: disrupt,
            dodge: dodge,
            avoids: block + parry + disrupt + dodge,
          });
        } else {
          this.fightData[this.fightData.length - 1]['kills'] += kill;
          this.fightData[this.fightData.length - 1]['assists'] += assist;
          this.fightData[this.fightData.length - 1]['deaths'] += died;
          this.fightData[this.fightData.length - 1]['no_ap'] += no_ap;
          this.fightData[this.fightData.length - 1]['overheal'] += overheal;
        }
      }
    }
  }

  setTime(dateStr, timeStr) {
    var timestamp = new Date();
    dateStr[0] = dateStr[0] * 1 + 2000;
    timestamp.setFullYear(dateStr[0], dateStr[1] - 1, dateStr[2]);
    timestamp.setHours(timeStr[0]);
    timestamp.setMinutes(timeStr[1]);
    timestamp.setSeconds(timeStr[2]);
    return <any>timestamp;
  }

  versus(data) {
    let enemies = 0;
    let dps = 0;
    Object.keys(data.careerData).forEach((career) => {
      enemies += data.careerData[career].chars.length;
      if (data.careerData[career].dps) {
        dps += data.careerData[career].chars.length;
      }
    });

    return [enemies, dps];
  }
}

/** Builds and returns a new User. */
function createNewUser(id: number): UserData {
  const name =
    NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
    ' ' +
    NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
    '.';

  return {
    id: id.toString(),
    name: name,
    progress: Math.round(Math.random() * 100).toString(),
    color: COLORS[Math.round(Math.random() * (COLORS.length - 1))],
  };
}
