import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParseService {
  private dataSource = new BehaviorSubject<any>(false);
  dataReady$ = this.dataSource.asObservable();

  constructor() { }

  parseLine(charName, line) {
    let lineArray = line.split(']')
    let dmg : any = false
    if(lineArray.length==3) {
      let dataStr = lineArray[0].substring(1);
      let timeStr = lineArray[1].substring(1);
      let combatStr = lineArray[2].substring(1);
      let combatStrArray = combatStr.split(" ")
      //console.log(timeStr, combatStr)      
      var msg : any = false;
      //incoming damage
      if(combatStr.indexOf(' hits you ') > -1) {        
        msg = "inc dmg"
        var i = 0
        var dmgIndex = 0
        combatStrArray.forEach(el => {
          if(el == "damage.") {
            dmgIndex = i
          }
          i += 1
        });
        let dmg = combatStrArray[dmgIndex-1]*1
        //console.log(dmg)
      }
      //avoided inc damage - Parry  
      // Guard Parry    
      if(combatStr.indexOf('You parried ') > -1) {
        //console.log(timeStr, combatStr)
        if(combatStr.indexOf('You parried your ') > -1) {
          msg = 'Guard Parry'
        }
        else {
          msg = 'Inc. Dmg Parry'
        }
      }
      if(msg) {
        //console.log(combatStr)
        //console.log(msg)
      }
    }
    if(dmg) {
      this.dataSource.next(dmg)
    }
  }
}
