<div class="upload-form mat-app-background">
    <mat-form-field class="example-full-width" color="accent">
        <mat-label>Character Name</mat-label>
        <input type="text" matInput [(ngModel)]="charName">
        <button mat-button *ngIf="charName" matSuffix mat-icon-button aria-label="Clear" (click)="charName=''; $event.target.blur()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
     <mat-form-field>
        <mat-label>Realm</mat-label>
        <mat-select [(value)]="charRealm">
            <mat-option value="order">
                Order
            </mat-option>
            <mat-option value="destro">
                Destro
            </mat-option>
        </mat-select>
    </mat-form-field>


    <button [disabled]="!charName" color="accent" type="button" mat-raised-button (click)="fileInput.click()">UPLOAD COMBAT LOG</button>
    <input hidden (change)="selectFile($event)" #fileInput type="file" accept=".log">
</div>
<div *ngIf="loading">
    Loading...
</div>

<div [hidden]="!ready">
    <!--
    <h3>Deathblows!</h3>
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 db-table">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Player </th>
            <td mat-cell *matCellDef="let element">
                <img *ngIf="!element.career" class="small-icon" src="assets/images/icons/dummy.png" />
                <img *ngIf="element.career" class="small-icon" [src]="careerIcon(element.career)" />
                {{element.name}}
            </td>
        </ng-container>
        <ng-container matColumnDef="dbs">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Kills </th>
            <td mat-cell *matCellDef="let element"> {{element.dbs}} </td>
        </ng-container>
        <ng-container matColumnDef="died">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Died </th>
            <td mat-cell *matCellDef="let element"> {{element.died}} </td>
        </ng-container>
        <ng-container matColumnDef="kdiff">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>+/- </th>
            <td mat-cell *matCellDef="let element"> {{element.kdiff}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons></mat-paginator>
    -->

</div>

<!--mat-form-field class="example-full-width" *ngIf="fights">
    <mat-label>Search names, abilities</mat-label>
    <input type="text" matInput [(ngModel)]="fightSearch">
</mat-form-field-->

<!--
<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSourceX" matSort>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
      </ng-container>

      <ng-container matColumnDef="progress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Progress </th>
        <td mat-cell *matCellDef="let row"> {{row.progress}}% </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
      </ng-container>

      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.color}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsX"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsX;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
-->
<div *ngIf="ready" style="margin: 20px 0;">
    <!--div>{{ totals.incdamage }} {{ totals.mitigated }} {{ totals.absorbed }}</div-->
    <mat-accordion class="example-headers-align" multi>
        <div *ngFor="let fight of fights ; let i = index" >
            <div *ngIf="fight.fightData.length > 0" class="fight">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Fight #{{i+1}} Started: {{fight.fightData[0].timestamp }} Duration: {{fight.fightData.length }}s
                        </mat-panel-title>
                        <mat-panel-description>
                            <div class="kb-header">
                                <div>
                                    <div>Kills/Assists</div>
                                    <span class="kills">{{ fight.fightInfo.kills }}/{{ fight.fightInfo.assists }}</span>
                                </div>
                                <div>
                                    <div>Deaths</div>
                                    <span class="died">{{ fight.fightInfo.died }}</span>
                                </div>
                                <div>
                                    <div>Versus (DPS)</div>
                                    <span class="versus">{{ versus(fight)[1] }} </span>
                                </div>
                                <div>
                                    <div>Versus (All)</div>
                                    <span class="versus">{{versus(fight)[0]}} </span>
                                </div>
                                <!--div>
                                    <div>INC</div>
                                    <span class="">
                                        x{{ (fight.fightInfo.totalInc / fight.fightData.hits ) }}x
                                    </span>
                                </div-->
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="fight-header">
                        <div class="fight-dmg-info">
                            <div class="fight-info">
                                <div class="heading">Incoming Damage</div>
                                <div>Total: {{ fight.fightInfo.totalInc }}</div>
                                <div>DPS: {{ (fight.fightInfo.totalInc / fight.fightData.length) | number: '1.0-0' }}</div>
                                <div>Max 3s Burst: {{ peakIn(fight.fightData) }}</div>
                                <div>Mitigated: {{ fight.fightInfo.incmitigation / (fight.fightInfo.incmitigation + fight.fightInfo.totalInc) * 100 | number:'1.0-0' }}% ({{ fight.fightInfo.incmitigation }})</div>
                                <div>Absorbed: {{ fight.fightInfo.incabsorbed }}</div>
                                <div>Avoidance: {{ avoidance(fight) }}</div>
                                <div>Critical Hits: <span *ngIf="false">{{ fight.fightInfo.critsIn | number : '1.0-0'}}</span> {{ critsIn(fight.fightInfo) }}</div>
                                <div>You died: {{ fight.fightInfo.died }}</div>
                                <div *ngIf="fight.careerData" class="career-table">
                                    <div class="class header">
                                        <div>&nbsp;</div>
                                        <div>Chars</div>
                                        <div>Att.</div>
                                        <div>Blocked</div>
                                        <div>Avoided</div>
                                        <div>Hits</div>
                                        <div>Crits</div>
                                        <div>Mitigation</div>
                                        <div>Dmg/Hit</div>
                                        <div>Damage</div>
                                    </div>
                                    <div *ngFor="let class of enemies">
                                        <div class="class" *ngIf="class.name != 'Guard'">
                                            <img *ngIf="class.id" src="https://www.returnofreckoning.com/forum/styles/dawar/theme/images/careers/{{class.id}}.png" />
                                            <div [matTooltip]="getCareerCharsTooltip(fight.careerData, class.name)">{{ getCareerChars(fight.careerData, class.name) }}</div>
                                            <div>{{ getCareerAttacks(fight.careerData, class.name) }}</div>
                                            <div>{{ getCareerBlocked(fight.careerData, class.name) }}</div>
                                            <div>{{ getCareerAv(fight.careerData, class.name) }}</div>
                                            <div>{{ getCareerHits(fight.careerData, class.name) }}</div>
                                            <div>{{ 100 * getCareerCrits(fight.careerData, class.name)/getCareerHits(fight.careerData, class.name) | number:'1.0-0'}}<span *ngIf='getCareerCrits(fight.careerData, class.name)'>%</span></div>
                                            <div>{{ getCareerMit(fight.careerData, class.name) }}</div>
                                            <div>{{ getCareerDmg(fight.careerData, class.name) / getCareerHits(fight.careerData, class.name) | number : '1.0-0' }}</div>
                                            <div>{{ getCareerDmg(fight.careerData, class.name) }}</div>
                                        </div>
                                        <div class="guard-data" *ngIf="class.name == 'Guard'">
                                            <div class="guard-1 header">
                                                <div>&nbsp;</div>
                                                <div>Attacks</div>
                                                <div>Hits</div>
                                                <div>Damage</div>
                                            </div>
                                            <div class="guard-1">
                                                <div>Guard</div>
                                                <div>{{ getCareerAttacks(fight.careerData, class.name) }}</div>
                                                <div>{{ getCareerHits(fight.careerData, class.name) }}</div>
                                                <div>{{ getCareerDmg(fight.careerData, class.name) }}</div>
                                            </div>
                                            <div class="guard-2 header">
                                                <div>Avoid</div>
                                                <div>Block</div>
                                                <div>Parry</div>
                                                <div>Disrupt</div>
                                                <div>Dodge</div>
                                            </div>
                                            <div class="guard-2">
                                                <div>{{ getCareerAv(fight.careerData, class.name) }}</div>
                                                <div>{{ getCareerBlocked(fight.careerData, class.name) }}</div>
                                                <div>{{ getCareerParry(fight.careerData, class.name) }}</div>
                                                <div>{{ getCareerDisrupt(fight.careerData, class.name) }}</div>
                                                <div>{{ getCareerDodge(fight.careerData, class.name) }}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="fight-info">
                                <div class="heading">Outgoing Damage</div>
                                <div >Total: {{ fight.fightInfo.totalOut }}</div>
                                <div>DPS: {{ (fight.fightInfo.totalOut / fight.fightData.length) | number: '1.0-0' }}</div>
                                <div>Max 3s Burst: {{ peakOut(fight.fightData) }}</div>
                                <div>Avoidance: TBD</div>
                                <div>Critical Hits: {{ critsOut(fight.fightInfo) }}</div>
                                <div>Your Kills/Assists: {{ fight.fightInfo.kills }}/{{ fight.fightInfo.assists }}</div>
                                <div>-</div>
                                <div>-</div>
                                <div *ngIf="fight.careerDataOut" class="career-table">
                                    <div class="class header">
                                        <div>&nbsp;</div>
                                        <div>Chars</div>
                                        <div>Att.</div>
                                        <div>Blocked</div>
                                        <div>Avoided</div>
                                        <div>Hits</div>
                                        <div>Crits</div>
                                        <div>Mitigation</div>
                                        <div>Dmg/Hit</div>
                                        <div>Damage</div>
                                    </div>
                                    <div *ngFor="let class of enemies">
                                        <div class="class" *ngIf="class.name != 'Guard'">
                                            <img *ngIf="class.id" src="https://www.returnofreckoning.com/forum/styles/dawar/theme/images/careers/{{class.id}}.png" />
                                            <div>-</div>
                                            <div>{{ getCareerAttacks(fight.careerDataOut, class.name) }}</div>
                                            <div>{{ getCareerBlocked(fight.careerDataOut, class.name) }}</div>
                                            <div>{{ getCareerAv(fight.careerDataOut, class.name) }}</div>
                                            <div>{{ getCareerHits(fight.careerDataOut, class.name) }}</div>
                                            <div>{{ 100 * getCareerCrits(fight.careerDataOut, class.name)/getCareerHits(fight.careerDataOut, class.name) | number:'1.0-0'}}<span *ngIf='getCareerCrits(fight.careerDataOut, class.name)'>%</span></div>
                                            <div>{{ getCareerMit(fight.careerDataOut, class.name) }}</div>
                                            <div>{{ getCareerBlocked(fight.careerDataOut, class.name)/ getCareerHits(fight.careerDataOut, class.name) | number:'1.0-0' }}</div>
                                            <div>{{ getCareerDmg(fight.careerDataOut, class.name) }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="fight.abilityUsed.length > 0" class="career-table">
                                    <div class="class ability header">
                                        <div>&nbsp;</div>
                                        <div>Att.</div>
                                        <div>Blocked</div>
                                        <div>Avoided</div>
                                        <div>Hits</div>
                                        <div>Crit</div>
                                        <div>Mitigation</div>
                                        <div>Dmg/Hit</div>
                                        <div>Tot. Dmg</div>
                                    </div>
                                    <div *ngFor="let ability of fight.abilityUsed">
                                        <div class="class ability">
                                            <span *ngIf="fight.abilityData[ability]['image']"><img class="ability-image" src="https://builder.returnofreckoning.com/images/abilities/{{ fight.abilityData[ability]['image'] }}.png" />{{ ability }}</span>
                                            <span *ngIf="!fight.abilityData[ability]['image']"><div style="width:20px;display: inline-block; padding-right: 5px; ">&nbsp;</div>{{ ability }}</span>
                                            <div>{{ fight.abilityData[ability]['hits'] }}</div>
                                            <div>{{ 100 * fight.abilityData[ability]['blocked'] / fight.abilityData[ability]['hits'] | number : '1.0-0' }}%</div>
                                            <div>{{ 100 * fight.abilityData[ability]['avoided'] / fight.abilityData[ability]['hits'] | number : '1.0-0' }}%</div>
                                            <div>{{ fight.abilityData[ability]['succ_hits'] }}</div>
                                            <div>{{ 100 * fight.abilityData[ability]['crits'] / fight.abilityData[ability]['hits'] | number : '1.0-0' }}%</div>
                                            <div>{{ 100 * fight.abilityData[ability]['mitigated'] / (fight.abilityData[ability]['dmg'] + fight.abilityData[ability]['mitigated'] ) | number : '1.0-0' }}%</div>
                                            <div>{{ ( fight.abilityData[ability]['dmg'] / fight.abilityData[ability]['hits'] ) | number : '1.0-0'}}</div>
                                            <div>{{ fight.abilityData[ability]['dmg'] }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="fight-info">
                                <div class="heading">Incoming Healing</div>
                                <div >Total Incoming: {{ fight.fightInfo.totalIncHeal }}</div>
                                <div>Self Heals: {{ fight.fightInfo.totalIncHealSelf }}</div>
                                <div>HPS: {{ (fight.fightInfo.totalIncHeal / fight.fightData.length) | number: '1.0-0' }}</div>
                                <div>Max 3s Burst: TBD</div>
                                <div>Critical Healszsz: TBD</div>
                                <div>Overheal: TBD</div>
                            </div>
                            <div class="fight-info">
                                <div class="heading">Outgoing Heals</div>
                                <div>TBD</div>
                            </div>
                        </div>
                    </div>
                    <div class='heading timestamp-heading'>Outgoing Damage</div>
                    <mat-form-field class="example-full-width" *ngIf="fights" color="accent" style="margin-right: 20px;">
                        <mat-label>Search name, ability</mat-label>
                        <input type="text" matInput [(ngModel)]="fightSearchOut[i]">
                        <button mat-button *ngIf="fightSearchOut[i]" matSuffix mat-icon-button aria-label="Clear" (click)="fightSearchOut[i]=''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-checkbox class="example-margin" [(ngModel)]=avgSingle[i]>Averaged Single Target Damage</mat-checkbox>
                    <div class="timestamps">
                        <div *ngFor="let timestamp of fight.fightData" class="timestamp">
                            <div *ngIf="timestamp.timestamp != 'blank'">
                                <div *ngIf="!avgSingle[i]">
                                    <div class="totalInc">{{sumOutDmg(timestamp, i)}}&nbsp;</div>
                                    <div *ngFor="let hit of timestamp.outDmg">
                                        <div *ngIf="isDmg(hit.dmg) && outSearch(i, hit)" class="hit outdmg {{hit.ability}} {{hit.crit}}" [matTooltip]="myTooltip(hit)" [ngStyle]="{'height.px': barHeight(hit.dmg) }">
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="avgSingle[i]">
                                    <div class="totalInc">{{sumOutDmgAvg(timestamp, i)}}&nbsp;</div>
                                    <div *ngFor="let hit of timestamp.outDmgAvg">
                                        <div *ngIf="isDmg(hit.dmg)" class="hit outdmg {{hit.ability}} {{hit.crit}}" [matTooltip]="myTooltip(hit)" [ngStyle]="{'height.px': barHeight(hit.dmg) }">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="timestamp.timestamp == 'blank'" class="hit">
                                &nbsp;
                            </div>
                            <div class="avoidance">
                                <div *ngIf="timestamp.kills > 0" class="totalInc killer" matTooltip="Deathblow">
                                    {{ timestamp.kills}}
                                </div>
                                <div *ngIf="timestamp.kills == 0" class="totalInc killer">
                                    &nbsp;
                                </div>
                                <div *ngIf="timestamp.assists > 0" class="totalInc assisted" matTooltip="Assist">
                                    {{ timestamp.assists}}
                                </div>
                                <div *ngIf="timestamp.assists == 0" class="totalInc assisted">
                                    &nbsp;
                                </div>
                                <div *ngIf="timestamp.no_ap > 0" class="totalInc warning" matTooltip="Not Enough Action Points">
                                    <span class="material-icons">warning</span>
                                </div>
                                <div *ngIf="timestamp.no_ap == 0" class="totalInc assisted">
                                    <span style="visibility: hidden;" class="material-icons">warning</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='heading timestamp-heading'>Incoming Damage</div>
                    <div class="legend">
                        <div><div class="incdmg leg"></div>Direct</div>
                        <div><div class="attack leg"></div>AA</div>
                        <div><div class="Guard leg"></div>Self/Guard</div>
                        <div><div class="Morale leg"></div>Morale</div>
                    </div>
                    <mat-form-field class="example-full-width" *ngIf="fights" color="accent">
                        <mat-label>Search name, ability, career</mat-label>
                        <input type="text" matInput [(ngModel)]="fightSearch[i]">
                        <button mat-button *ngIf="fightSearch[i]" matSuffix mat-icon-button aria-label="Clear" (click)="fightSearch[i]=''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <!--mat-form-field>
                        <mat-label>Any Class</mat-label>
                        <mat-select [(value)]="selectedClass[i]">
                          <mat-option *ngFor="let class of order" [value]="class.name">
                            {{ class.name }}
                          </mat-option>
                        </mat-select>
                    </mat-form-field-->
                    <div class="timestamps">
                        <div *ngFor="let timestamp of fight.fightData" class="timestamp">
                            <div *ngIf="timestamp.deaths > 0" class="totalInc killed">
                                <span class="material-icons">local_hospital</span>
                            </div>
                            <div *ngIf="timestamp.timestamp == 'blank'" class="hit">
                                &nbsp;
                            </div>
                            <div *ngIf="timestamp.timestamp != 'blank'">
                                <div class="totalInc">{{sumIncDmg(timestamp, i) }}</div>
                                <div *ngFor="let hit of timestamp.incDmg">
                                    <div *ngIf="isDmg(hit.dmg) && inSearch(i, hit)" class="hit incdmg {{hit.ability}} {{hit.crit}} {{ hit.category}}" [matTooltip]="myTooltip(hit)" [ngStyle]="{'height.px': barHeight(hit.dmg) }">
                                    </div>
                                </div>
                            </div>
                            <div class="avoidance">
                                <div [ngClass]="{'muted': timestamp.blocks == 0}" matTooltip="Blocks"><span>{{ timestamp.blocks }}&nbsp;</span></div>
                                <div [ngClass]="{'muted': timestamp.parries == 0}"  matTooltip="Parries"><span >{{ timestamp.parries }}</span></div>
                                <div [ngClass]="{'muted': timestamp.dodge == 0}"  matTooltip="Dodge"><span >{{ timestamp.dodge }}</span></div>
                                <div [ngClass]="{'muted': timestamp.disrupt == 0}"  matTooltip="Disrupts"><span >{{ timestamp.disrupt }}</span></div>
                                <div class="all-avoid" [ngClass]="{'muted': timestamp.avoids == 0}"><span >{{ timestamp.avoids}}</span></div>
                            </div>
                        </div>
                    </div>
                    <!--h2>HEALING</h2-->
                    <div class='heading timestamp-heading'>Incoming Heals</div>
                    <div class="legend">
                        <div><div class="incheal leg"></div>Direct Heal</div>
                        <div><div class="DHOT leg"></div>HoT</div>
                        <div><div class="selfHeal leg"></div>Self Heal</div>
                        <div class="overheal"><span class="material-icons">error</span> Overhealed</div>
                    </div>
                    <mat-form-field class="example-full-width" *ngIf="fights" color="accent">
                        <mat-label>Search name, ability</mat-label>
                        <input type="text" matInput [(ngModel)]="fightSearchHeal[i]">
                        <button mat-button *ngIf="fightSearchHeal[i]" matSuffix mat-icon-button aria-label="Clear" (click)="fightSearchHeal[i]=''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <div class="timestamps">
                        <div *ngFor="let timestamp of fight.fightData" class="timestamp">
                            <div *ngIf="timestamp.timestamp == 'blank'" class="hit">
                                &nbsp;
                            </div>
                            <div *ngIf="timestamp.timestamp != 'blank'">
                                <div class="totalInc">{{sumIncHeal(timestamp)}}</div>
                                <div *ngFor="let heal of timestamp.incHeal">
                                    <div *ngIf="isDmg(heal.value) && healSearch(i, heal)" class="hit incheal {{heal.self}} {{heal.duration}}" [matTooltip]="myTooltip(heal)" [ngStyle]="{'height.px': barHeight(heal.value) }">
                                    </div>
                                </div>
                            </div>
                            <div class="avoidance">
                                <div *ngIf="timestamp.overheal > 0" class="totalInc overheal" matTooltip="Overheal">
                                    <span class="material-icons">error</span>
                                </div>
                                <div *ngIf="timestamp.overheal == 0" class="totalInc killer">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let p of fight.allHealed" class="all-healed">
                        <div>{{ p.name }} {{ careerIcon(p.career) }}</div>
                        <div>{{ p.healed }}</div>
                        <div>{{ p.overhealed }}</div>
                        <div>{{ p.total_healed }}</div>
                        <div>{{ p.overheal_percent }}%</div>
                    </div>
                </mat-expansion-panel>
            </div>
        </div>
    </mat-accordion>
</div>